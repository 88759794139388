import { FC, ReactElement } from 'react'

type WrapperFunction = (children: ReactElement) => ReactElement
interface Props {
  condition: boolean
  wrapper: WrapperFunction
  falseWrapper?: WrapperFunction
  children: ReactElement
}

// conditionally wrap a component with another
export const ConditionalWrapper: FC<Props> = ({ condition, wrapper, falseWrapper, children }) => {
  return condition ? wrapper(children) : typeof falseWrapper === 'function' ? falseWrapper(children) : children
}
